<template>
  <main id="charge">
    <section class="section-top point">
      <h1 class="section-top-title mb-3">Hyundai x U-POWER 充電卡綁定</h1>
      <!-- <p class="section-top-text">
        綁定後完成手機簡訊驗證即成為會員
        <br />您可即時查詢點數紀錄，享有各種會員權益
      </p> -->
    </section>
    <section class="section-main">
      <form @submit.prevent="handleSubmit">
        <ul class="form" :class="{ 'form-shake': isNotFound }">
          <li class="phone form-item">
            <label for="phone">手機號碼</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              class="input is-static-line"
              inputmode="tel"
              required
              maxlength="10"
              placeholder="請輸入您的手機號碼"
              v-model="phone"
              ref="phoneInput"
              autofocus
              :class="[phoneDefault ? '' : phoneValid ? 'valid' : 'invalid']"
              @input="clearAlert"
            />
            <div v-if="!phoneValid && !phoneDefault" class="error">
              <small>請輸入有效的手機號碼格式09XXXXXXXX</small>
            </div>
            <div class="desc">
              <small>
                請輸入您購車時提供給南陽實業的手機號碼，並以此手機號碼完成註冊
                U-POWER 會員。若您不確定登記的手機號碼或有任何疑問請聯繫：<br />南陽實業
                HYUNDAI 客服中心 :
                <a href="tel: 0800-00-33-55">&nbsp;0800-00-33-55</a>。
              </small>
            </div>
          </li>
          <li class="form-item">
            <label for="card-no">充電卡卡號</label>
            <input
              type="tel"
              name="card-no"
              id="card-no"
              class="input is-static-line"
              required
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="10"
              placeholder="請輸入您的充電卡卡號"
              v-model="cardNo"
              ref="cardInput"
              :class="[cardDefault ? '' : cardValid ? 'valid' : 'invalid']"
              @input="clearAlert"
            />
            <div v-if="!cardValid && !cardDefault" class="error">
              <small>請輸入有效的充電卡卡號</small>
            </div>
          </li>
          <li class="form-item check">
            <input
              id="termsAndConditions1"
              type="checkbox"
              class="invoice-type-checkbox"
              v-model="termsAndConditions1"
            /><label for="termsAndConditions1"
              >我已了解並同意
              <a href="https://www.u-power.com.tw/event/hyundaixpowercard.html"
                >U-POINT充電卡使用條件</a
              ></label
            >
          </li>
          <li class="check">
            <input
              id="termsAndConditions2"
              type="checkbox"
              class="invoice-type-checkbox"
              v-model="termsAndConditions2"
            /><label for="termsAndConditions2"
              >我同意充電卡點數一經綁定則無法取消或將點數移轉至其他帳號。</label
            >
          </li>
          <div class="submit form-item_action">
            <button
              type="submit"
              class="button is-rounded is-fullwidth is-link"
              :disabled="
                isProcessing ||
                !phoneValid ||
                !cardValid ||
                cardDefault ||
                phoneDefault ||
                !termsAndConditions1 ||
                !termsAndConditions2
              "
            >
              {{ isProcessing ? "送出中..." : "確認送出" }}
            </button>
          </div>
        </ul>
      </form>
      <!-- <div class="message-box" v-if="isNotFound === true">
        <p><strong>無相關資料</strong><br />請與您的服務業代或 U-POWER 客服聯絡。</p>
      </div> -->
      <div class="message-box" v-if="isNotFound === true">
        <p>
          <strong>無相關資料</strong><br />請與您的服務業代或 U-POWER 客服聯絡。
        </p>
      </div>
    </section>
  </main>
</template>
<script>
import verifyAPI from "../apis/verify.js";

export default {
  name: "CardLink",
  data() {
    return {
      isProcessing: false,
      phone: "",
      cardNo: "",
      check: false,
      termsAndConditions1: false,
      termsAndConditions2: false,
      phoneValid: false,
      phoneDefault: true,
      cardValid: false,
      cardDefault: true,
      isNotFound: false,
    };
  },
  methods: {
    validPhone(phone) {
      let re = /^09[0-9]{8}$/g;
      return re.test(phone);
    },
    validCardNo(no) {
      let re = /^[0-9]{10}$/g;
      return re.test(no);
    },
    clearAlert() {
      this.isNotFound = false;
    },
    async handleSubmit() {
      try {
        this.isProcessing = true;
        const payload = {
          cardnumber: this.cardNo,
          phonenumber: this.phone,
        };
        const { data, status } = await verifyAPI.SMSverify.verifyExist(payload);
        if (!(status >= 200 && status < 300)) {
          this.isProcessing = false;
          this.isNotFound = true;
          throw new Error(status);
        }
        this.$router.push({
          name: "SMSverify",
          params: { phone: this.phone, cardNo: this.cardNo, verifyData: data },
        });
      } catch (error) {
        this.isProcessing = false;
        this.isNotFound = true;
        console.log(error);
      }
    },
  },
  watch: {
    phone: function (val) {
      if (val.length === 10 && this.validPhone(val)) {
        this.phoneValid = true;
        this.phoneDefault = false;
        this.$refs.cardInput.focus();
      } else if (val.length === 10) {
        this.phoneDefault = false;
        this.phoneValid = false;
      } else if (val.length === 0) {
        this.phoneDefault = true;
      }
    },
    cardNo: function (val) {
      if (val.length === 10 && this.validCardNo(val)) {
        this.cardValid = true;
        this.cardDefault = false;
      } else if (val.length === 10) {
        this.cardDefault = false;
        this.cardValid = false;
      } else if (val.length === 0) {
        this.cardDefault = true;
      }
    },
  },
};
</script>

<style>
.termsAndConditions {
  display: flex;
}
</style>
